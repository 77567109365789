@import "../../../../assets/styles/variables.module.scss";

.avatar {
  border-radius: 50%;
  object-fit: cover;
  color: $light-grey;
  flex-shrink: 0;
}

.patient {
  color: $primary;
  flex-shrink: 0;
}
