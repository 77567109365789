@import '../../../../assets/styles/globals.scss';

.rowDisabled {
  opacity: 0.4;
}
.trashIcon {
  cursor: pointer;
}
.blockTitle {
  font-weight: 600;
}
.scheduleTopLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.updatedBy {
  font-size: 12px;
  color: $grey-1;
}
.button {
  @include ButtonPrimary;
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 600;
  border-radius: 2px;
  width: 115px;
  height: 40px;
  color: $textColor;
}

.methodIcon {
  position: relative;
  top: 5px;
  width: 24px;
  height: 24px;
  color: $icons;
}

.checkboxDisabled {
  color: $grey-3;
}

.arrowDown {
  transition: transform 0.3s ease-in-out;

  path {
    fill: $primary;
  }
  &.active {
    transform: rotate(180deg);
  }
}

.availabilityActions {
  position: relative;
  top: 2px;
}

:global {
  .add-slot-button {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0;
    cursor: pointer;
  }
  .slot-color-jamaica{
    color: $dark-blue;
  }

  .availability-table,
  .availability-collapse {
    .error-message {
      font-size: 12px;
      color: $error;
      z-index: 1;
    }

    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $checkboxAviability;
        }
      }

      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border: 2px solid $checkboxAviability;
        .ant-checkbox-checked {
          background-color: $checkboxAviability;
        }

        &:after {
          top: 40%;
          width: 7px;
          height: 13px;
        }
      }
    }

    .ant-checkbox-wrapper {
      &.error {
        .ant-checkbox-inner {
          border-color: $error;
        }
      }
      &:hover {
        .ant-checkbox-inner {
          border-color: $dark-blue !important;
        }
      }
      &.ant-checkbox-wrapper-checked {
        &:hover {
          .ant-checkbox-inner {
            background-color: $dark-blue !important;
          }
        }
      }
    }

    .ant-checkbox-wrapper-disabled {
      svg {
        opacity: 0.5;
      }
    }

    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: $grey-3;

        &:hover {
          border-color: $grey-3;
        }

        &:after {
          border-color: $background;
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $grey-3;
        }

        &:hover {
          .ant-checkbox-inner {
            border-color: $grey-3;
          }
        }
      }
    }

    .ant-picker {
      border: 1px solid $dark-blue;
      width: 70px;
      font-size: 12px;
      padding: 4px;
      text-align: center;

      &.error {
        border-color: $error;
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        display: none;
      }
    }
  }

  .availability-table {
    .ant-table {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
    .slot-day {
      vertical-align: baseline;
    }
    .ant-table-thead {
      padding: 10px 16px;

      .ant-table-cell {
        background-color: $background;
        font-size: 12px;
        color: $grey-1;
        font-weight: 600;

        &:before {
          display: none;
        }
      }
      th {
        &.slot-day {
          width: 20%;
        }
        &.slot-from {
          width: 35%;
        }
      }
    }

    .slot-deleted {
      .slot-methods-checkboxes,
      .ant-picker {
        opacity: 0.4;
      }
    }

    .slot-methods-container,
    .slot-methods-checkboxes {
      display: flex;
      justify-content: space-between;
    }

    .slot-methods-container {
      flex-flow: wrap;
    }

    .slot-methods-checkboxes {
      position: relative;
      top: -1px;
      width: 240px;
    }

    .time-pickers-container {
      position: relative;
    }

    .error-message {
      position: absolute;
      top: 33px;
      width: 300px;
    }

    .slot-methods,
    .slot-from {
      vertical-align: top;
    }

    .slot-methods {
      & > div {
        height: 32px;
        margin-bottom: 20px;
      }
    }
    .slot-from {
      & > div {
        height: 32px;
        margin-bottom: 20px;
      }
    }
  }
}

@include respond(tablet) {
  :global {
    .availability-table {
      .ant-table-thead {
        th {
          &.slot-day {
            width: 16%;
          }
          &.slot-from {
            width: 32%;
          }
        }
      }

      td.ant-table-cell {
        padding: 12px !important;
      }

      .slot-from > div,
      .slot-methods > div {
        margin-bottom: 15px;
      }

      .ant-picker-input input,
      .add-slot-button {
        font-size: 13px;
      }


      .ant-checkbox-wrapper span {
        font-size: 14px;
      }
    }
  }
}

@include respond(mobile) {
  .blockTitle {
    margin-bottom: 0;
    font-size: 13px;
  }
  .scheduleTopLine {
    flex-wrap: wrap;
    align-items: baseline;
    flex-direction: column-reverse;

    .updatedBy {
      margin: 0;
    }
  }

  .availabilityActions {
    position: absolute;
    right: 4px;
    top: 0;
  }

  .mobileContent {
    .item {
      margin-top: 20px;
      position: relative;
    }
  }

  .label {
    color: #777;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .button {
    position: relative;
    width: 100%;
    margin-top: 20px;
    color: $textColor;
  }

  .dayCheckboxContainer {
    width: fit-content;

    span {
      font-size: 13px;
    }
  }

  :global {
    .availability-collapse {
      .slot-methods-checkboxes {
        position: relative;
        .error-message {
          position: absolute;
          top: 28px;
          width: 300px;
        }
      }
    }

    .slot-methods-checkboxes {
      display: flex;
      justify-content: space-between;
      width: 230px;
    }

    .panel-disabled {
      .ant-picker {
        border-color: $grey-3;
      }
    }

    .add-slot-button {
      margin-top: 15px;
    }


    .availability-collapse.ant-collapse {
      border: 0;
      border-radius: 0;
      background-color: transparent;
      margin-left: -12px;
      margin-right: -12px;

      .disable-collapse {
        svg {
          path {
            fill: $grey-3;
          }
        }
      }

      .ant-collapse-content {
        border: 0;
      }

      .ant-collapse-item {
        border-radius: 0;
      }

      .ant-collapse-content-box {
        padding: 0 12px 12px 12px;
      }

      .ant-picker {
        width: 80px;
      }
    }
  }
}
