@import '../../../../assets/styles/globals';

.appointmentByPatientCard {
  @include Card;

  margin: 12px 0;
  padding: 15px;

  .cardHeader {
    display: flex;
    align-content: center;
    border-bottom: 1px solid $grey-5;
    padding-bottom: 10px;
  }

  .cardFooter {
    padding-top: 10px;
    display: flex;
  }
}

.accentText {
  color: $link;
}

.name {
  text-decoration: underline;
  margin-left: 15px;
}

.name,
.accentText {
  font-weight: 500;
}

.appointmentMeta {
  display: flex;
  margin-left: auto;
}

.historyViewButton {
  @include ButtonPrimary;
  font-weight: 600;
  border-radius: 2px;
  width: 104px;
  height: 40px;
  margin-left: auto;
  color: $textColor;
}

@include respond(tablet) {
  .appointmentByPatientCard {
    border-radius: 0;

    .cardHeader {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .name {
      margin: 0;
    }

    .clinicType {
      font-weight: 600;
    }
  }

  .appointmentComment {
    margin-bottom: 10px;

    :global {
      p {
        font-weight: 400 !important;
      }
    }
  }

  .appointmentSchedule {
    font-size: 16px;
    font-weight: 700;
  }
}

@include respond(mobile) {
  .appointmentByPatientCard {
    .clinicType {
      font-weight: 400;
    }
  }

  .historyViewButton {
    width: 100%;
  }

  .cardHeader {
    flex-direction: column;
  }

  .appointmentMeta {
    margin: 5px 0 0 0;
  }

  .appointmentComment {
    :global {
      p {
        font-size: 13px;
      }
    }
  }
}
