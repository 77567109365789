@import '../../../../assets/styles/variables.module.scss';
@import '../../../../assets/styles/mixins.scss';

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.weekDays {
  margin-top: 10px;
  button {
    width: 45px !important;
  }
}

.content {
  align-self: stretch;
}

.actions {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.repeat {
  margin-top: 10px;
  padding-top: 20px;
  min-height: 110px;
  border-top: 1px solid $gainsboro;

  & > div {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  :global {
    .ant-radio-group .ant-radio-wrapper {
      margin-inline-end: 0;
    }
  }
}

.ends {
  margin-top: 30px;
  margin-bottom: 30px;
  > div:first-child {
    margin-bottom: 10px;
  }
  label {
    width: 50px;
  }
}

.group {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: 50px;
  margin-left: 30px;
  gap: 16px;
}

.last {
  margin-bottom: 30px;
}

.message {
  text-align: center;
}

.item {
  margin-bottom: 10px;
}

.checkbox {
  margin-top: 10px;
  padding-left: 2px;
  pointer-events: none;
}

.description {
  margin-top: 10px;
  min-height: 24px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.icon {
  color: $icons;
  width: 24px;
  height: 24px;
}

@include respond(tablet) {
  .long {
    flex-wrap: wrap;
  }
}

@media (max-width: 540px) {
  .devider {
    width: calc(100vw - 45px);
  }
}

@media (max-width: 387px) {
  .deviderDay {
    width: calc(100vw - 45px);
  }
}
