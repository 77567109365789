@import 'globals';

body {
  margin: 0;
  font-family: $body-font-family, sans-serif;
  font-size: 14px;
  line-height: 24px;
  background-color: $background;
}

.centered-content {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

.Header {
  background-color: $dark-blue;
  height: 72px;
}

.Content {
  padding: 30px;
}

.ant-input[disabled],
.ant-input[disabled]:hover,
.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper-disabled:hover {
  border-color: $grey-3;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $common-action-width;
}

.common-button {
  @include ButtonLG;
  width: $common-action-width;
  margin-top: 22px;
}

.userInfoName {
  color: $link;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  text-decoration-line: underline;
}

.userInfoId {
  color: $black;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}

.userInfoEmail,
.providerClinicService,
.userInfoId {
  display: flex;
  color: $black;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  gap: 8px;
  margin-bottom: 12px;
}
.ant-picker .ant-picker-clear {
  opacity: 1;
}

@include respond(tablet) {
  .Content {
    padding: 5px 13px;
  }
}

@include respond(mobile) {
  .Content {
    .ant-tabs {
      width: 100%;
    }
    .ant-tabs-nav-wrap {
      width: 100%;
      justify-content: center;
    }
  }

  .providerClinicService,
  .userInfoId {
    line-height: 1;
  }

  .form-container {
    width: 85%;
  }
}

.ant-picker-dropdown {
  @media (max-width: 720px) and (min-width: 680px), (max-width: 605px) {
    .ant-picker-panel-container .ant-picker-panels {
      animation: none;
      display: inline-grid;
    }
  }
}
