@import '../../../../assets/styles/globals';

@include respond(mobile) {
  :global {
    .appointment-history-switch {
      width: 100%;
      .ant-radio-button-wrapper {
        width: 50%;
      }
    }
  }
}
