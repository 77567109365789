@import '../../assets/styles/globals';
@import '../../assets/styles/mixins.scss';

.patientsContainer {
  margin: auto;
}

.patientsTitle {
  font-family: $body-font-family;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: $black;
}
