.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
}
.date {
  margin: 20px 0 5px 0;
  font-size: 16px;
  font-weight: 600;
}
.time {
  margin: 0;
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;
  text-transform: uppercase;
}
.statusLine {
  display: flex;
  margin-top: 5px;
}
.infoMessage {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: -0.05px;
}
.button {
  width: 100%;
  margin: 10px 0;
}

.testerName {
  text-decoration: underline;
  font-weight: 500;
}
