.container {
  display: inline;
  :global {
    .ant-switch {
      &.ant-switch-disabled {
        background-color: #3c3c3c;
      }
    }
  }
}
