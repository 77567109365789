@import '../../../../assets/styles/globals';

.profileInfo {
  position: relative;
  border: 1px solid $grey-5;
  border-radius: 4px;
  padding: 30px;

  .profilePhotoContainer {
    display: flex;

    .profileName {
      font-weight: 700;
      font-size: 16px;
      margin-left: 15px;
      color: $onBackground;
    }
  }
}

.profileImage {
  color: $dark-blue;
}

.button {
  @include ButtonPrimary;
  position: absolute;
  right: 0;
  font-weight: 600;
  border-radius: 2px;
  width: 217px;
  height: 40px;
  color: $textColor;
}

@include respond(tablet) {
  .profileInfo {
    margin-bottom: 10px;
    padding: 0;
    border: 0;

    .arrowDown {
      color: $primary !important;
      transition: transform 0.3s ease;
    }

    .active {
      transform: rotate(180deg);
    }

    .collapse {
      width: 60%;
    }

    :global {
      .ant-collapse,
      .ant-collapse-item {
        border: 0;
        background: transparent;
        box-shadow: 0;
        border-radius: 0;

        .ant-collapse-header {
          padding: 0;
        }

        .ant-collapse-header-text {
          font-size: 16px;
          font-weight: 700;
        }

        .ant-collapse-content {
          border: 0;
        }

        .ant-collapse-content-box {
          padding: 0;
        }

        .ant-collapse-header-text {
          flex: initial !important;
          margin-inline-end: 0 !important;
        }
      }
    }
  }
}

@include respond(mobile) {
  .profileInfo {
    .collapse {
      width: 100%;
    }

    :global {
      .ant-collapse,
      .ant-collapse-item {
        .ant-collapse-header-text {
          margin-inline-end: auto !important;
        }
      }
    }
  }
}
