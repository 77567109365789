.notificationBar {
  position: absolute;
  background-color: #7e7e7e;
  width: 20%;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  left: 0;
  right: 0;
  top: 46px;
  margin: auto;
}

.notificationError {
  position: relative;
  background: rgb(63, 65, 73);
  text-align: center;
  color: red;
}
