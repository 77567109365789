@import '../../../../assets/styles/mixins.scss';

.provider {
  .switch {
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
  }
  :global {
    .ant-radio-group {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

.blockTitle {
  font-weight: 600;
}

.appointmentsContainer {
  position: relative;
  // min-height: calc(100vh - 240px);
  // outline: 1px solid red;
}

.observerTarget {
  position: absolute;
  bottom: 0;
}

@include respond(mobile) {
  .switch {
    padding-top: 15px;
  }
  .appointmentsContainer {
    padding-bottom: 65px;
  }
}
