@import "../../../../assets/styles/globals";

.title {
  color: $black;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  padding: 0;
  margin: 0;
  &:first-child {
    margin-top: 24px;
  }
  &:nth-child(2) {
    margin-bottom: 17px;
  }
}

.text {
  color: $black;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}

.buttonsBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 25px;
  margin-top: 60px;
}

.button {
  height: 50px;
  color: $textColor;
}
