@import '../../../../assets/styles/globals.scss';

.profileContainer {
  position: relative;
  border: 1px solid $grey-5;
  border-radius: 4px;
  padding: 30px;
}

.profilePhotoContainer {
  display: flex;
  align-items: center;
  min-height: 72px;
  margin-bottom: 15px;
}

.userTitle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 15px;

  p {
    margin: 0;
  }
}

.userName,
.title {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

.userName {
  font-size: 16px;
  font-weight: 700;
}

.title {
  font-size: 12px;
}

.editIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: $secondaryIcon;
}

.modal {
  top: -60px !important;
  padding: 0;

  :global {
    .ant-modal .ant-modal-content {
      padding: 30px;
    }
  }
}

@include respond(tablet) {
  .profileContainer {
    padding: 0;
  }
  .profileContainer {
    padding-top: 50px;
    border: none;
  }

  .providerPage {
    padding: 0 0 30px 0;

    .arrowDown {
      color: $secondary !important;
      transition: transform 0.3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }

    :global {
      .ant-collapse,
      .ant-collapse-item {
        border: 0;
        background: transparent;
        box-shadow: 0;
        border-radius: 0;

        .ant-collapse-header {
          padding: 0;
        }

        .ant-collapse-content {
          border: 0;
        }

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  .providerFullName {
    font-weight: 600;
  }
}

@include respond(mobile) {
  .profileContainer {

    .profilePhotoContainer {
      margin-bottom: 0;

      .avatar {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .editIcon {
      right: 0;
    }
  }
}
