@import '../../assets/styles/globals';

.description {
  font-weight: 500;
  margin: -10px 0 3px 0;
  color: $green;
}

@include respond(mobile) {
  :global {
    .form-container {
      .common-button {
        width: 100%;
      }
    }
  }
}
