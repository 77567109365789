@import '../../../../assets/styles/globals.scss';

:global {
  .ant-radio-button-wrapper {
    width: 108px;
    text-align: center;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid $primary !important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }
  .ant-radio-button-wrapper:hover {
    color: $primary;
  }
}

@include respond(tablet) {
  :global {
    .appointment-view-switch {
      .ant-radio-button-wrapper {
        width: 40px;
        padding: 0;
        span + span {
          line-height: 50px;
        }
      }
    }
  }
}

.icon {
  color: $icons;
  &.active {
    path {
      fill: $background;
    }
  }
  path {
    fill: $primary;
  }
}
