@import '../../../../assets/styles/globals.scss';

.pagination {
  text-align: center;
}
.date {
  font-weight: 600;
}

@include respond(mobile) {
  .date {
    margin: 5px 0;
    font-size: 12px;
  }
}
