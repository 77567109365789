.controlButton {
  width: 54px !important;
  height: 54px;
  background-color: transparent;
  border-radius: 0;
  margin-right: 15px;
  border-color: #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controlButtonIcon {
  width: 24px;
  height: 24px;
}