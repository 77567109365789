@import '../../assets/styles/globals';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: 500;
    margin: 12px 0;
  }
}
