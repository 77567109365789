@import '../../../../assets/styles/variables.module.scss';

$out-of-range-color: $grey-3;
$out-of-range-bg-color: transparent;

$calendar-border: $grey-5;
$cell-border: $grey-5;
$cell-margin: 5px;

// Each calendar segment is 1/7th.
$segment-width: 0.14286%;

$event-border-radius: 2px;
$event-zindex: 4;

$current-time-color: #BB6666;

$day-off-bg: #F5F5F5;
$day-off-current-range-bg: $grey-4;
