@import '../../assets/styles/globals';

.content {
  display: flex;
  margin-top: 20px;
}

.leftPanel {
  width: 32%;
}

.rightPanel {
  width: 68%;
  padding-left: 30px;

  .rightPanelContent {
    display: flex;
    justify-content: space-between;

    .pageTitleProvider {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .historySwitchContainer {
    margin-bottom: 20px;
  }
}

.button {
  font-weight: 600;
  font-size: 15px;
  border-radius: 2px;
  width: 217px;
  height: 40px;
  color: $textColor;
}

@include respond(tablet) {
  .content {
    flex-wrap: wrap;
  }

  .leftPanel,
  .rightPanel {
    width: 100%;
    padding: 0;
  }

  .pageTitleProvider {
    margin-bottom: 10px;
  }
}

@include respond(mobile) {
  .button {
    position: sticky;
    bottom: 10px;
    width: 100%;
    font-size: 14px;
    color: $textColor;
  }
}
