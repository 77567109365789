@import '../../../../assets/styles/globals';

.buttonOutlined {
  @include ButtonOutlined;
  width: 217px;
  height: 40px;
  margin: 40px 0;
}

@include respond(tablet) {
  .buttonOutlined {
    width: 280px;
    margin: 20px 0 0 0;
  }
}

@include respond(mobile) {
  .buttonOutlined {
    width: 100%;
  }
}
