@import '../../../../assets/styles/globals';

.lastVisitedLabel {
  font-weight: 500;
}

.bookProviderButtonWrapper {
  display: flex;
  margin-top: 30px;

  .button {
    width: 217px;
    height: 40px;
    margin-left: auto;
    padding: 8px 0;
    border-radius: 2;
  }
}

@include respond(mobile) {
  .bookProviderButtonWrapper {
    .button {
      width: 100%;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
}
