@import '../../../../assets/styles/variables.module.scss';

.link {
  text-decoration: underline;
  color: $link;
}

.clinicServiceTable {
  :global {
    .ant-table {
      table {
        border-radius: 8px;
        border: 1px solid $gainsboro;
      }
    }
  }
}
