@import '../../../../assets/styles/globals';

.personalData {
  .personalDataTitle {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
  }

  p,
  span {
    font-size: 13px;
  }

  p {
    font-weight: 600;
    margin: 0;

    span {
      font-weight: 400;
    }
  }
}

@include respond(tablet) {
  .personalData {
    p {
      margin-top: 10px;
    }
  }
}
