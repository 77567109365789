@import 'variables.module';

@mixin ButtonLG {
  font-weight: 600;
  box-shadow: 0px 10px 10px -3.75px rgba(104, 78, 22, 0.25), 0px 2.29px 2.29px -2.5px rgba(104, 78, 22, 0.63),
    0px 0.6px 0.6px -1.25px rgba(104, 78, 22, 0.72);
}

@mixin ButtonBase {
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 2px;
}

@mixin ButtonPrimary {
  @include ButtonBase;
  border: 1px solid $primary;
}

@mixin ButtonOutlined {
  @include ButtonBase;
  color: $primary;
  border: 1px solid $primary;
  background: $background;
}

@mixin ButtonSuccessOutlined {
  @include ButtonBase;
  color: $green;
  border: 1px solid $green;
  background: $background;
}

@mixin ButtonPrimaryLow {
  @include ButtonBase;
  color: $secondary;
  background: $backgroundCancelButton;
  border: 1px solid $secondary;
}

@mixin Card {
  border-radius: 10px;
  border: 1px solid $border-disabled;
  background: $background;
  box-shadow: 0px 0px 16px 0px rgba(167, 167, 167, 0.25);
  min-height: 50px;
}

@mixin respond($breakpoint) {
  @if $breakpoint == mobile {
    @media (max-width: $tablet) { @content; }
  }
  @if $breakpoint == tablet {
    @media (max-width: $desktop) { @content; }
  }
}
