@import '../../../../assets/styles/globals.scss';

.form {
  width: 100%;
  box-sizing: border-box;
}

.title {
  margin: 0 0 30px 0;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.avatarLine {
  display: flex;
  gap: 65px;
  margin-bottom: 35px;
}

.line {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

.input {
  width: 50%;
}

.prefix {
  width: 300px;
}

.bio {
  :global {
    textarea.ant-input {
      height: 120px;
      resize: none;
    }
  }
}

.phone {
  :global {
    .ant-input {
      border-left: none;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  gap: 10px;
}

.button {
  width: 217px;
  height: 40px;
  color: $textColor;
}

.prefixOption {
  display: flex;
  align-items: center;
  gap: 10px;

  .flag {
    width: 30px;
  }
}

@include respond(tablet) {
  .avatarLine {
    gap: 25px;
  }
  .prefix {
    width: 270px;
  }
}


@include respond(mobile) {
  .line {
    flex-direction: column;
    gap: 5px;
    margin-bottom: 5px;
  }
  .input {
    width: 100%;
  }

  .actions {
    flex-direction: column;

    .button {
      width: 100%;
      color: $textColor;
    }
  }
}
