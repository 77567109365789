@import '../../../assets/styles/globals';

.buttonContainer {
  display: flex;
  align-items: center;
  margin-left: -10px;

}

.button {
  border: none;
  background-color: $background;

  &[disabled] {
    background-color: $background;
  }
}
