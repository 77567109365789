@import '../../../../assets/styles/mixins.scss';

.actions {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  width: 100%;

  .button {
    min-width: 104px;
    height: 40px;
    padding: 8px auto;
    margin-left: 15px;
    border-radius: 0;
  }
}

@include respond(mobile) {
  .actions {
    flex-direction: column;

    .button {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }
}
