@import '../../../../assets/styles/variables.module.scss';
@import '../../../../assets/styles/globals.scss';

.ant-notification-notice-wrapper {
  background: transparent !important;
  border-radius: 12px !important;
  width: 358px !important;

  .ant-notification-notice.notification {
    background-image: linear-gradient(to left top, #b5b9be, #a9abaf, #9d9ea1, #909193, #848485);
    border-radius: 12px;
    height: 85px;
    width: 358px;
    cursor: pointer;

    .ant-notification-notice-close {
      color: $grey-1;
      top: 3px;
      right: 3px;
    }

    .ant-notification-notice-message {
      height: 100%;
      color: $background;
      line-height: 16px;
      font-size: 13px !important;
      margin-inline-start: 45px !important;
      margin-bottom: 3px !important;
      position: relative;
      top: 3px;

      .title {
        font-weight: 700;
      }

      .now-label {
        position: absolute;
        right: -5px;
        top: 9px;
      }

      p {
        margin: 0 0 5px 0;
      }
    }

    .ant-notification-notice-icon {
      transform: translateY(20%);
      width: 32px;
      height: 32px;
    }
  }
}

@include respond(mobile) {
  .ant-notification-notice-wrapper {
    background: transparent !important;
    border-radius: 12px !important;
    width: 271px !important;

    .ant-notification-notice.notification {
      width: 271px;
    }

    .notification-body {
      position: absolute;
      top: -10px;
    }
  }
}
