.card {
  margin-top: 30px;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.description {
  padding: 15px 15px 15px 0;
  font-size: 13px;
  line-height: 18px;
  white-space: pre-wrap;
}
