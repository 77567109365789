@import "../../../assets/styles/globals";

.title {
  color: $black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  padding: 0;
  margin: 0;
  margin-top: 37px;
}

.patient {
  color: $black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  padding: 0;
  margin: 0;
  margin-bottom: 49px;
}

.text {
  color: $black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

.optionsBlock {
  margin-top: 26px;
  width: 54%;
  display: flex;
  padding-bottom: 127px;
  justify-content: center;
}

.optionsIcon {
  padding-left: 6px;
  width: 30px;
  height: 24px;
  margin-right: -2px;
}

.buttonOption {
  font-weight: 600;
  border-radius: 2px;
  width: 50%;
  height: 64px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: space-around;
}

.buttonOption:hover {
  color: black !important;
  border-color: black !important;
}

.buttonsBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 25px;
}

.buttonPrimary {
  @include ButtonPrimary;
  font-weight: 600;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  color: $textColor;
}

.buttonPrimaryLow {
  @include ButtonPrimaryLow;
  width: 100%;
  height: 50px;
}
