@import '../../../assets/styles/variables.module.scss';

.input {
  width: 40px;
  input {
    padding-right: 8px !important;
    text-align: right !important;
  }
}

.button {
  padding: 0;
  width: 24px !important;
  height: 24px !important;
  color: $primary;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.controls {
  display: flex;
  flex-direction: column;
}
