@import '../../../assets/styles/globals';

.button {
  @include ButtonLG;
  color: $textColor;
}
// TODO: should be a part of formItem component
.formItem {
  margin-bottom: 30px;
  :global {
    .ant-form-item-explain-error {
      width: max-content;
    }
  }
}

@include respond(mobile) {
  .formItem {
    margin-bottom: 15px;

    :global {
      .ant-form-item-explain-error {
        width: 100%;
        font-size: 12px;
      }
    }
  }
}
