@import '../../../../assets/styles/globals';

.assignedProviderCard {
  @include Card;

  margin: 12px 0;
  padding: 15px;
  display: flex;

  .cardActions {
    display: flex;
    align-items: center;
    margin-left: auto;
    button {
      width: 104px;
      height: 40px;
      padding: 8px 0;
      margin: 0 8px;
      border-radius: 2px;
    }
  }

  .contactInfoContainer {
    display: flex;
    :global {
      .userInfoEmail {
        margin-right: 20px;
      }
    }
  }

  .personalData {
    padding: 0 15px;

    .nameContainer {
      margin-bottom: 10px;
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .accentText {
    color: $link;
  }

  .name {
    text-decoration: underline;
    cursor: pointer;
  }

  .name,
  .accentText {
    font-weight: 500;
  }
}

.icon {
  width: 24px;
  height: 24px;
  color: $icons;
}


.providerMainInfo {
  display: flex;
}

@include respond(tablet) {
  .assignedProviderCard {
    border-radius: 0;
  }

  .lastVisit {
    margin-top: 5px;
    display: block;
  }

  .avatar {
    align-self: center;
  }
}

@include respond(mobile) {
  .assignedProviderCard {
    flex-direction: column;
    padding-top: 0;

    .nameContainer {
      margin-left: 15px;
      font-size: 13px;
    }

    .lastVisit {
      margin-top: 0;
    }

    .personalData {
      padding: 0;
    }

    .contactInfoContainer {
      flex-direction: column;
    }

    .avatar {
      width: 44px !important;
      height: 44px !important;
    }

    .cardActions {
      margin-top: 10px;
      margin-left: 0;

      button {
        width: 100%;
        margin: 0;
      }
    }
  }
}
