@import '../../../../../assets/styles/globals.scss';

.tag {
  display: flex;
  align-items: center;
  border: 1px solid $black;
  border-radius: 2px;
  padding: 0 2px 0 8px;
  min-width: 120px;
  height: 30px;
  gap: 4px;
}

.label {
  flex: 1;
  color: $black;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
  }
}

.listTag {
  padding: 0 12px 0 4px !important;
  height: 34px;
  gap: 4px;
  justify-content: flex-start !important;
}

.listTagLabel {
  flex: 1;
  color: $black;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
}

.listTagClose {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 18px;
    height: 18px;
  }
}
