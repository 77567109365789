@import '../../../../assets/styles/globals';

.userInfo {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0;
}

.userType {
  margin: 0 0 5px 0;
  font-weight: 600;
}

.header {
  position: absolute;
  right: 0;
  top: 0;
}

.closeButton {
  border: none;
  background-color: $background;
  margin-right: -5px;

  svg {
    width: 20px;
    height: 20px;
  }

  &[disabled] {
    background-color: $background;
  }
}

@include respond(tablet) {
  .userInfo {
    &:not(.fullWidth) {
      width: 45%;

      .closeButton {
        right: 25px;
      }
    }
    &.fullWidth {
      width: 100%;
      padding-left: 10px;

      .closeButton {
        right: 10px;
      }
    }
  }
}
@include respond(mobile) {
  .userInfo {
    &:not(.fullWidth) {
      width: 95%;
      margin: 10px 0 0 0;

      .closeButton {
        right: -15px;
      }
    }
    &.fullWidth {
      width: 100%;
      padding-left: 10px;

      .closeButton {
        right: 15px;
      }
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  color: $icons;
}
