@import '../../../../assets/styles/mixins.scss';

.toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  .label {
    font-size: 14px;
    flex: 1;
    padding: 0 12px;
  }

  @include respond(mobile) {
    &:nth-child(1) {
      padding-top: 15px;
    }
  }  
}
