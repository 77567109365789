@import './variables';

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 5px 3px 5px; // padding top & bottom defines gap between events in cell
  &[style='flex-basis: 14.2857%;'] {
    max-width: 14.2857%;
  }
}

.rbc-show-more {
  @extend .rbc-ellipsis;
  z-index: $event-zindex;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  height: 11px;
  color: $dark-blue;
  &:hover,
  &:focus {
   color: lighten(#12365a, 20%);
  }
}

.rbc-month-view {
  position: relative;
  border: 1px solid $calendar-border;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  padding-bottom: 9px;
  height: 100%; // ie-fix

  .rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    line-height: 31px;
    font-weight: 500;
    font-size: 14px;
    color: $grey-1;
    text-transform: uppercase;
    height: 32px;
    border-bottom: 1px solid $cell-border;
  }
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 9px;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0; // postcss will remove the 0px here hence the duplication below
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; // ie-fix
  padding-left: 9px;
  padding-right: 9px;

  .rbc-row-bg {
    padding-left: 9px;
    padding-right: 9px;
  }

  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(1))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(1)
    .rbc-button-link,
  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(2))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(2)
    .rbc-button-link,
  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(3))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(3)
    .rbc-button-link,
  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(4))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(4)
    .rbc-button-link,
  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(5))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(5)
    .rbc-button-link,
  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(6))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(6)
    .rbc-button-link,
  &:has(.rbc-row-bg .rbc-day-bg.rbc-day-off-current-range:nth-child(7))
    .rbc-row-content
    .rbc-row
    .rbc-date-cell:nth-child(7)
    .rbc-button-link {
    pointer-events: none;
  }
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  margin-top: $cell-margin;
  margin-left: $cell-margin;
  margin-right: $cell-margin;
  border-bottom: 1px solid $cell-border;
  height: 29px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.rbc-now {
    background-color: $light-yellow;
    & > button {
      width: 18px;
      height: 18px;
      background-color: $primary;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 50%;
      padding-block: 0;
      padding-inline: 0;
    }
  }
}

.rbc-row-bg {
  @extend .rbc-abs-full;
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
  margin: $cell-margin;
}

.rbc-overlay {
  position: absolute;
  z-index: $event-zindex + 1;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 5px;
}

.rbc-overlay-list {
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rbc-overlay-header {
  padding-bottom: 20px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  color: $dark-blue;
}

.rbc-overlay-close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  right: 2px;
  cursor: pointer;
}
