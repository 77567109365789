@import '../../../../assets/styles/globals';

.topLine {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 40px 0;

  .appointmentId {
    margin: 0;
    font-weight: 600;
  }
}
.date {
  display: flex;
  margin-top: 20px;
}
.card {
  display: flex;
  border: 1px solid $grey-3;
  border-radius: 10px;
  min-height: 210px;

  .leftSide,
  .rightSide {
    width: 50%;
    padding: 5px 20px;
  }

  .leftSide {
    border-right: 1px solid $grey-3;
  }

  .rightSide {
    display: flex;
    flex-direction: column;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}

.button {
  height: 50px;
  width: 215px;
  margin-left: 15px;
  border-radius: 0;
  font-weight: 500;
  text-wrap: wrap;

}

.participantsTitle {
  font-weight: 600;
  font-size: 13px;
}

@include respond(tablet) {
  .topLine {
    margin-bottom: 10px;
  }

  .appointmentId,
  .date {
    font-size: 13px;
  }

  .card {
    min-height: 130px;
    margin-top: 15px;
    box-shadow: 0px 0px 9px 0px #00000040;
    border: none;
    border-radius: 0;

    .rightSide {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0 12px 5px 12px;
      font-size: 13px;
    }
  }
}

@include respond(mobile) {
  .topLine {
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 0;

    .appointmentId {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .date {
    margin-top: 10px;
  }

  .card {
    .rightSide {
      flex-wrap: wrap;
      padding-bottom: 10px;
    }
  }

  .actions {
    flex-wrap: wrap;
    margin-top: 40px;

    .button {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}
