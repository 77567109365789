@import '../../../assets/styles/variables.module.scss';

.controlledSelect {
  position: relative;

  .select {
    position: relative;
    width: 100%;
    margin: 20px 0;
  }

  .label {
    position: absolute;
    top: -10px;
    font-size: 14px;
    font-weight: 600;
  }

  :global {
    .ant-select {
      .ant-select-selector {
        border-color: $black;
      }
    }
  }
}
