@import '../../../assets/styles/variables.module.scss';

.controlledInput {
  position: relative;

  &.error {
    :global {
      .react-tel-input {
        .form-control,
        .flag-dropdown {
          border-color: $error;
        }
      }
    }

    .input {
      border-color: $error;
    }

    .label {
      color: $error;
    }
  }

  .input {
    position: relative;
    margin: 20px 0;
  }

  .label {
    position: absolute;
    top: -10px;
    font-size: 14px;
    font-weight: 600;
  }

  .validationMessage {
    position: absolute;
    bottom: -12px;
    color: $error;
    font-size: 12px;
  }

  :global {
    .react-tel-input {
      margin: 20px 0;

      .form-control,
      .flag-dropdown {
        border-color: $black;
      }

      .form-control {
        width: 100%;
        height: 32px;
      }
    }
  }
}
