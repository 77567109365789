.container {
  display: flex;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
}
