@import '../../assets/styles/globals';

.container {
  margin: auto;
}

.title {
  font-family: $body-font-family;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: $black;
}

.tableFilterIcon {
  display: flex;
  justify-content: end;
}

.iconButton {
  color: $icons;
  background-color: $background;
}
