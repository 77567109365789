@import '../../../../assets/styles/globals';

.statusProvider {
  color: $secondary;
}
.statusActive {
  color: $green;
}
.statusCanceled {
  color: $red-1;
}
.statusPatient {
  color: $warning;
}
.statusPeernavigatorAppointment {
  color: $grey-7;
}
.statusCompleted {
  color: $grey-2;
}

.disabled {
  color: $grey-1;
}

.status {
  font-weight: 600;
}


@include respond(tablet) {
  .status {
    font-size: 13px;
  }
}
