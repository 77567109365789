@import '../../../../assets/styles/variables.module.scss';

.typeImage {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  color: $secondaryIcon;

  &.disabled path {
    fill: $grey-1;
  }

  &.peerNavigator {
    color: $grey-7;
  }
}
