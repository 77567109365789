.container {
  margin: 10px 0 16px 0;
  gap: 8px;
}

.counter {
  min-width: 120px;
}

.item {
  min-width: 80px;
}

.tagButton {
  cursor: pointer;
}

.list {
  overflow-y: auto;
  padding: 4px 0;
  max-height: 256px;
}
