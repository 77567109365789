@import '../../../assets/styles/mixins.scss';

.container {
  position: fixed;
  z-index: 10;
  top: 20px;
  right: 30px;
  cursor: pointer;
  display: flex;
}

.rightGap {
  margin-right: 20px;
}

.iconUser{
  color: $primary;
}

@include respond(tablet) {
  .container {
    display: none;
  }
}
