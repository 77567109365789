$admin-top-offset: 80px;
$provider-top-offset: 150px;

.containerAdmin {
  height: calc(100% - $admin-top-offset - 30px);
}

.containerProvider {
  height: calc(100% - $provider-top-offset - 30px);
}

:export {
  adminTopOffset: $admin-top-offset;
  providerTopOffset: $provider-top-offset;
}
