@import '../../assets/styles/globals';

.container {
  display: flex;
  height: 100vh;
  justify-content: space-between;

  .hidden {
    display: none;
  }

  .linesContainer {
    display: flex;

    .blueLine,
    .yellowLine {
      width: 55px;
    }

    .yellowLine {
      background-color: #c79f44;
    }
    .blueLine {
      background-color: #1c3557;
    }
  }

  .content  {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

@include respond(mobile) {
  .container {
    .linesContainer {
      .blueLine,
      .yellowLine {
        width: 20px;
      }
    }
  }
}