@import '../../../assets/styles/globals';

.container {
  display: flex;
  :global {
    .ant-radio-button-wrapper {
      margin-right: 8px;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      width: 114px;
      color: $black;
      border-width: 2px;
      border-radius: 2px;
      padding-inline: 0;

      &-disabled {
        color: $link;
        background-color: $background;
        border-color: $border-disabled;
      }

      // these styles needed only for tooltip
      & > span:last-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
