@import '../../../../assets/styles/globals';

.comment {
  .title {
    font-weight: 600;
    margin-bottom: 5px;
  }
  .text {
    margin: 0;
    font-size: 13px;
    line-height: 16px;

    &.shortenedComment {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

@include respond(tablet) {
  .comment {
    .title {
      font-size: 13px;
    }
  }
}