@import '../../../../assets/styles/mixins.scss';
@import '../../../../assets/styles/variables.module.scss';

.appointmentItemProvider {
  @include Card;

  display: flex;

  margin: 12px 0;
  padding: 15px;

  .info {
    width: 100%;

    .accentText {
      padding-left: 10px;
      color: $dark-blue;
    }
  }
  .bottomLine {
    display: flex;
    margin-top: 5px;
  }
}

.appointmentItemAdmin {
  margin: 12px 0;
  padding: 15px;
  border: 1px solid $grey-5;
  border-radius: 10px;

  &.completed {
    color: $grey-2;

    .accentText {
      color: $grey-2;
    }
  }

  .info {
    display: flex;
    position: relative;
  }

  .mainInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .appointmentDetails {
      white-space: nowrap;
      height: 25px;
    }
  }

  .avatar {
    margin-right: 15px;
  }

  .status {
    display: inline-flex;
  }

  .date {
    margin-right: 20px;
  }

  .accentText {
    color: $link;
  }

  .patientLine {
    width: 100%;
    margin: 5px 0 15px 0;
  }
}

.name,
.accentText {
  font-weight: 500;
  text-decoration: underline;
}

.providerName {
  white-space: nowrap;
}

.attendeesInfo {
  display: block;
}

@include respond(tablet) {
  .date {
    font-size: 16px;
    font-weight: 700;
  }
  .mainInfo {
    display: flex;
    flex-wrap: wrap;

    .attendeesInfo {
      display: flex;
      flex-direction: column;
    }

    .date,
    .patientLine {
      width: 100%;
    }
    .name {
      margin: 0;
    }
    .patientLine {
      margin-bottom: 10px;
    }
    .avatar {
      width: 58px;
      height: 58px;
    }
  }

  .appointmentItemAdmin,
  .appointmentItemProvider {
    padding: 10px;
    border-radius: 0;
    box-shadow: 0px 0px 9px 0px #00000040;
  }

  .appointmentItemProvider {
    flex-direction: column;

    .info {
      display: flex;
      justify-content: space-between;
    }
    .topLine {
      display: flex;
      flex-direction: column;

      .accentText {
        padding: 0;
      }
    }
    .bottomLine {
      margin-top: 0;
    }
    .actionsContainer {
      position: relative;
      top: -8px;
    }
  }
}

@include respond(mobile) {
  .appointmentItemAdmin,
  .appointmentItemProvider {
    padding: 15px;
  }
  .mainInfo {
    flex-direction: column;
    font-size: 13px;

    .avatar {
      position: absolute;
      margin: 0;
      right: 0;
    }

    .status {
      position: static;
    }

    .patientLine {
      margin-top: 0;
    }

    .date {
      display: block;
      width: 80%;
    }
  }

  .providerName {
    white-space: wrap;
  }

  .appointmentItemAdmin {
    .avatar {
      position: absolute;
      margin: 0;
      right: 0;
      top: -5px;
    }
  }
  .appointmentItemProvider {
    .info {
      display: block;
    }
    .actionsContainer {
      top: 0;
      margin-top: 15px;
    }
    .bottomLine {
      margin-top: 5px;
    }
  }
}

.reccurent {
  color: $primary;
  display: inline-block;
  height: 24px;
  margin-right: 6px;
  vertical-align: top;

  svg {
    width: 24px;
    height: 24px;
  }
}

.tester {
  background-color: $grey-44;
}
