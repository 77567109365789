@import '../../../../assets/styles/mixins.scss';

.date {
  margin: 0;
}

.empty {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@include respond(tablet) {
  .date {
    margin-top: 15px;
    font-weight: 600;
  }
}