@import '../../../../../assets/styles/globals.scss';

.icon {
  color: $icons;
}

.picker {
  padding: 0px 11px;
  width: 260px;

  :global {
    .ant-picker-input {
      padding-top: 4px;
      padding-bottom: 4px;
      border-bottom: 1px solid $primary;
    }

    .ant-picker-active-bar {
      background: $primary;
    }

    .ant-picker-clear {
      width: 20px;
    }

    .ant-picker-footer-extra {
      max-width: 290px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.popup {
  :global {
    .ant-picker-footer-extra {
      max-width: 290px;
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      justify-content: space-between;
    }
  }
}

@include respond(tablet) {
  .picker {
    position: relative;
    padding: 0;

    :global {
      .ant-picker-active-bar {
        margin-inline-start: 0;
      }
      .ant-picker-suffix {
        width: 24px;
        height: 24px;

        svg {
          display: none;
        }
      }
      .ant-picker-clear {
        right: 0;
      }
    }
  }
}
