@import '../../assets/styles/globals';

.content {
  display: flex;
  margin-top: 20px;
}

.leftPanel {
  width: 32%;
}

.rightPanel {
  position: relative;
  width: 68%;
  padding-left: 30px;
}

.button {
  position: absolute;
  right: 0;
  top: 0;
  font-weight: 600;
  border-radius: 2px;
  width: 217px;
  height: 40px;
  color: $textColor;
}

@include respond(tablet) {
  .rightPanel,
  .leftPanel {
    width: 100%;
    padding: 0;
  }

  .rightPanel {
    margin-top: 15px;
  }

  .leftPanel {
    :global {
      .edit-icon {
        top: 0px;
        right: 25px;
        width: 20px;
      }
    }
  }

  .content {
    flex-direction: column;
  }
}

@include respond(mobile) {
  .button {
    position: sticky;
    width: 100%;
    color: $textColor;
  }
}
