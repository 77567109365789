@import '../../assets/styles/globals';

.buttonPrimary {
  @include ButtonPrimary;
  font-weight: 600;
  border-radius: 2px;
  width: 217px;
  height: 40px;
  color: $textColor;
  &[disabled] {
    color: $background;
    background-color: $light-grey;
    border-color: $light-grey;
  }
}

.buttonPrimaryLow {
  @include ButtonPrimaryLow;
  width: 217px;
  height: 40px;
}

.card {
  @include Card;
  margin-top: 20px;
  display: flex;
  min-height: 286px;
}

.inputs {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 45px;
  border-right: 1px solid $grey-3;
  min-height: 100px;
  padding: 24px;
}

.title {
  color: $black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.duration {
  color: $black;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px;
}

.participants {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 14px 20px 14px 24px;
}

.participantsContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.buttonsBlock {
  margin-top: 74px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  color: $textColor;
}

.checkbox {
  margin-top: 15px;
}

.participantsTitle {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $grey-3;
  font-weight: 600;
}

.commentField {
  width: 380px;
}

.validationMessage {
  color: $error;
  font-size: 12px;
}

.dateAndTime {
  display: flex;
  gap: 20px;
}

.timePickerContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

@include respond(tablet) {
  .inputs {
    gap: 20px;
    border: none;
    padding: 0;

    .ant-space {
      gap: 20px;
    }
  }
  .card {
    flex-direction: column;
    border: none;
    box-shadow: none;
  }
  .commentField {
    width: 100%;
  }
  .title,
  .checkbox span {
    font-size: 13px;
  }
  .participantsContainer {
    min-height: 180px;
    margin-top: 10px;
    border: 1px solid $border-disabled;
    background: $background;
    box-shadow: 0px 0px 16px 0px rgba(167, 167, 167, 0.25);

    .participants {
      flex-direction: row;
      justify-content: space-between;
      gap: 15px;
      padding: 10px;
    }
  }
  .buttonPrimary,
  .buttonPrimaryLow {
    width: 300px;
    color: $textColor;
  }
  .participantsTitle {
    margin-top: 15px;
    display: block;
    height: auto;
    border: 0;
    font-size: 13px;
  }
  .buttonsBlock {
    margin-top: 40px;
    color: $textColor;
  }
  :global {
    .ant-space-vertical {
      width: 100%;
    }
  }
}

@include respond(mobile) {
  .dateAndTime {
    flex-direction: column;

    .datePicker {
      width: 226px;
    }
    .timePicker {
      width: 98px;
    }
  }
  .duration {
    font-size: 12px;
  }

  .participantsContainer {
    .participants {
      flex-direction: column;
      padding-bottom: 30px;
    }
  }
  .buttonsBlock {
    flex-direction: column;
    color: $textColor;

    .buttonPrimary,
    .buttonPrimaryLow {
      width: 100%;
      color: $textColor;
    }
  }
}
