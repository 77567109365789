@import '../../../../assets/styles/globals.scss';

.container {
  position: relative;
}
.count {
  position: absolute;
  top: -10px;
  font-size: 11px;
  padding: 1px 5px;
  background-color: $error;
  border-radius: 10px;
  font-weight: 400;
  color: $background;
}


@include respond(tablet) {
  .count {
    top: 13px;
    margin-left: 5px;
    line-height: 16px;
  }
}
