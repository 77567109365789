@import './variables';

.arrow {
  color: $primary !important;
  font-size: 24px !important;
  pointer-events: none !important;
}

.select {
  width: 100px;
  text-transform: capitalize;
}

.selectList {
  padding: 7px 0;
  
  :global {
    .ant-list-items .ant-list-item {
      padding: 5px 12px;
    }

    .ant-list-footer {
      padding: 0;
    }
  }
}

.popup {
  border-radius: 5px;
  padding: 0;
}

.checkbox {
  text-transform: capitalize;
}

.checkmark {
  color: $grey-3;
  margin-right: 8px;
  font-size: 20px;
  width: 18px;
}

.checked {
  color: $primary;
}

.footer {
  display: flex;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
  padding: 5px 12px;

  &.disabledItem {
    color: $grey-3;
    pointer-events: none;
  }
}
