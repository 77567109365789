@import '../../../../assets/styles/globals.scss';

.notification {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px #a7a7a740;
  position: relative;

  &.read {
    background: #0000000d;
    border: 1px solid #0000000d;
    .generalInfo {
      padding-left: 35px;
      justify-content: space-between;
    }
    .unreadDotContainer {
      display: none;
    }
    .userName,
    .title,
    .appointmentInfo,
    .availabilityInfo,
    .availabilityLink,
    .detailsLink {
      color: $grey-1;
    }
    .contactIcon path {
      fill: $grey-1;
    }
    :global {
      .notification-icon path {
        fill: $grey-1;
      }
    }
  }

  &.caseManager {
    .appointmentInfo {
      width: 46%;
      margin-right: 20px;
    }
    .doctorInfo {
      width: 32%;
    }
    .patientInfo {
      width: 27%;
    }
    .details {
      padding-left: 45px;
    }
    .clinicServiceType {
      white-space: nowrap;
    }
  }

  .generalInfo {
    display: flex;
    align-items: center;
    height: 83px;
    cursor: pointer;
  }

  .unreadDotContainer {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
  }

  .unreadDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    &.active {
      background-color: $link;
    }
  }

  .doctorInfo {
    width: 25%;
    display: flex;
    align-items: center;

    .doctorTitle {
      margin-left: 20px;
    }
  }

  .patientInfo {
    margin-left: 20px;
    width: 35%;
  }

  .userName,
  .title {
    color: $link;
    text-decoration: underline;
    font-weight: 600;
  }

  .title {
    width: 43.5%;
    margin-left: 10px;
  }

  .availabilityInfo {
    display: flex;
    width: 50%;
    margin-right: 3%;
  }

  .contactIcon {
    margin-right: 8px;
  }

  .appointmentInfo {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timeSlot {
    display: flex;
    white-space: nowrap;
  }

  .status {
    margin-left: 10px;
    white-space: nowrap;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 22%;
    margin-right: 15px;
  }

  .availabilityLink,
  .detailsLink {
    margin-right: 20px;
    color: $link;
  }

  .time {
    position: absolute;
    bottom: -25px;
    right: 5px;
    font-size: 12px;
    color: $grey-1;
  }

  .avatar {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border-radius: 50%;
  }

  .details {
    padding-left: 55px;
    padding-bottom: 20px;
  }

  .notificationLine {
    display: flex;
    margin-top: 5px;
    margin-right: 10px;

    img {
      width: 18px;
    }
  }
  .notificationBody {
    margin-left: 10px;
  }

  .seeMore {
    margin-left: 25px;
    cursor: pointer;
    color: $link;
    font-size: 13px;
    text-decoration: underline;
  }
}

.profileImage {
  flex-shrink: 0;
  color: $dark-blue;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

@include respond(tablet) {
  .notification {
    font-size: 12px;

    &.read {
      .generalInfo {
        padding-left: 28px;
      }
    }

    .generalInfo {
      height: auto;
      align-items: flex-start;
      position: relative;
      padding-bottom: 5px;
      padding-top: 6px;
    }

    .title {
      width: 41%;
      margin-left: 2px;
    }

    .availabilityInfo {
      margin-right: 5px;
    }

    .unreadDotContainer {
      margin: 2px 10px 10px;
    }

    .actions {
      align-items: flex-end;
      margin-right: 10px;
      flex-direction: column-reverse;
    }
    .details {
      padding-left: 27px;
    }
    .smallerScreenStatus {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      padding-bottom: 5px;
    }
    &.caseManager {
      .patientInfo {
        white-space: nowrap;
        margin-right: 5px;
      }
      .profileImage {
        width: 30px !important;
        height: 30px !important;
      }
      .doctorInfo {
        align-items: flex-start;
      }
      .infoContainer {
        display: flex;
        flex-direction: column-reverse;
        width: 40%;

        .doctorTitle,
        .appointmentInfo {
          width: 100%;
          margin-left: 0;
        }
      }
      .actions {
        justify-content: space-between;
      }
      .status {
        left: 28px;
      }
      .doctorTitle {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
      }
      .details {
        padding-left: 25px;
      }
      .smallerScreenStatus {
        padding-left: 51px;
      }
    }

    .patientInfo {
      width: 35%;
      margin-left: 0px;
      white-space: nowrap;
    }



    &.read {
      .smallerScreenStatus {
        .detailsLink,
        .status {
          margin-left: 17px;
        }
      }
    }
    .smallerScreenStatus {
      .status {
        margin-left: 17px;
      }
    }
  }
}

@include respond(mobile) {
  .notification {

    &.read {
      .generalInfo {
        padding-left: 15px;
      }
    }

    .generalInfo {
      flex-direction: column;
      padding-left: 25px;
    }
    .unreadDotContainer {
      position: absolute;
      top: 10px;
      left: 0;
    }
    .patientInfo {
      white-space: wrap;
    }
    .availabilityInfo,
    .title,
    .patientInfo,
    .appointmentInfo {
      width: 95%;
    }
    .actions {
      width: 50%;
    }
    .title,
    .patientInfo {
      margin-left: 3px;
    }
    .availabilityInfo {
      margin-top: 4px;
    }
    .actions {
      position: absolute;
      right: 0;
      justify-content: space-between;
      padding-bottom: 5px;
    }
    .details {
      padding: 0 17px 20px
    }
    .status {
      left: 25px;
    }
    .notificationLine {
      align-items: flex-start;
    }
    .timeSlot {
      white-space: normal;
    }
    &.caseManager {
      .doctorInfo,
      .infoContainer {
        width: 100%;
      }
      .userName {
        display: inline-block;
        width: 100%;
        white-space: wrap;
      }
      .patientInfo.patientInfo {
        white-space: wrap;
        width: calc(100% - 45px);
        margin-left: 40px;
      }
      .infoContainer {
        flex-direction: column;
      }
      .smallerScreenStatus {
        padding-left: 10px;
      }
      .doctorTitle {
        width: 75%;
      }
      .actions {
        width: 7%;
      }
      .details {
        padding: 0 17px 20px
      }
    }
    .notificationBody {
      line-height: 16px;
    }

    &.read {
      .smallerScreenStatus {
        flex-direction: column;


        .availabilityLink,
        .detailsLink,
        .status {
          margin-left: 8px;
        }
      }
    }
    .smallerScreenStatus {
      flex-direction: column;
      padding-left: 10px;

      .availabilityLink,
      .detailsLink,
      .status {
        margin-left: 15px;
      }
    }
  }
}
