@import '../../../../assets/styles/globals';

.wrap {
  position: relative;
  height: calc(100vh - 80px);
  background-color: rgb(63, 65, 73);
}

.permissionsModal {
  padding-top: 60px;

  :global {
    .ant-modal .ant-modal-content {
      border-radius: 0;
      padding: 16px;
      background-color: #353535;
      color: $background;
      text-align: center;
      font-size: 14px;

      .ant-modal-close {
        top: 16px;
        inset-inline-end: 16px;
        color: $background;
      }

      .ant-modal-body {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}

.browserPageImage {
  margin-top: 68px;
  margin-bottom: 58px;
}

.permissionsTitle {
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 6px;
}

.permissionsDescription {
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 84px;
}

.icon {
  height: 38px;
}

.tiles {
  height: calc(100% - 170px);
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.tile {
  height: calc(100% - 30px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.tileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 20px;
  min-width: 200px;
}

.tileVideoBlock {
  height: 100%;
  width: 100%;
}

.tileVideo {
  height: calc(100% - 40px);
}

.activeSpeaker {
  border: 1px solid $primary;
}

.singleTile {
  max-width: 50%;
}

.remoteStatus {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  gap: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.localVideo {
  position: fixed;
  bottom: 80px;
  right: 40px;
  width: 225px;
  height: 170px;
}

@include respond(mobile) {
  .tileVideoBlock {
    display: flex;
    flex-wrap: wrap;
  }
  .tiles {
    height: calc(100% - 190px);
    display: block;
  }
  .tile {
    width: 100%;
    height: 45%;
    margin-bottom: 40px;
  }
  .singleTile {
    max-width: 100%;
    height: 100%;
  }
  .localVideo {
    right: 10px;
    width: 150px;
    height: 100px;
  }
  .tileVideo {
    order: 2;
  }
  .tileInfo {
    gap: 15px;
  }
  .remoteStatus {
    order: 1;
    gap: 5px;
    padding-left: 15px;
    font-size: 13px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

@media (max-width: 320px) {
  .localVideo {
    width: 120px;
    height: 80px;
  }
}
