@import './variables';

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .rbc-timeslot-group {
    flex: 1;
    border-bottom: 1px solid $cell-border;
  }
}

.rbc-timeslot-group {
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter .rbc-timeslot-group {
  border: none;
  &:first-child .rbc-time-slot .rbc-label {
    color: transparent !important;
  }

  .rbc-time-slot {
    margin-top: -12px;

    .rbc-label {
      font-size: 13px;
      font-weight: 500;
      color: $grey-1;
    }
  }
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
  width: 66px;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;

  .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;

  &.rbc-now {
    font-weight: bold;
  }
}

.rbc-day-header {
  text-align: center;
}
