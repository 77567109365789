@import '../../../../assets/styles/globals.scss';

.blockTitle {
  margin: 8px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.line {
  margin: 8px 0;
  font-size: 13px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;

  .fieldTitle {
    font-weight: 600;
  }
}

.fieldValue {
  font-size: 13px;
  line-height: 16px;
}

.divider {
  border-bottom: 1px solid $grey-5;
}

.seeMore {
  display: block;
  margin: 10px 0;
  font-size: 13px;
  line-height: 16px;
  color: $link;
  font-weight: 600;
  cursor: pointer;
}

.patientList {
  padding-left: 25px;
}

.patientListItem {
  margin-bottom: 10px;

  &::marker,
  a {
    color: $link;
  }
}

.infoContainer {
  display: flex;
  flex-wrap: wrap;

  .line {
    width: 50%;
  }
}

.contactPersonType {
  display: inline-block;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.phoneIcon {
  width: 24px;
  color: $icons;
}
.emailIcon{
  color: $icons;
}
.contactInfo {
  display: flex;
  gap: 12px;
  margin: 8px 0;
}

.contactPersonName {
  text-decoration: underline;
}

@include respond(tablet) {
  .blockTitle,
  .line {
    margin: 6px 0;
  }
  .divider {
    margin: 10px 0;
  }
}

@include respond(mobile) {
  .blockTitle,
  .line {
    margin: 8px 0;
  }
}
